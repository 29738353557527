import React, { useRef, useEffect, useState } from 'react';
import cs from 'classnames';
import './index.scss';
import MbFormItem from '../../components/mbFormItem';
import { VerifyParamType } from './interface';
import Guid from '../../utils/guid';
import { API_POST_PNG, API_POST_USER_SEARCH, REG_ID_CARD, REG_PNG_CODE } from '../../config/api';
import Http from '../../utils/fetch';
import { ImgResultType, SearchResultType } from '../home/interface';
import { Toast, Modal } from 'antd-mobile';

const MbAccountSearch = () => {
  const [cardNo, setCardNo] = useState('');
  const [cardNoTip, setCardNoTip] = useState('身份证号不能为空');
  const [imgNo, setImgNo] = useState('');
  const [imgNoTip, setImgNoTip] = useState('图形验证码不能为空');
  const [imgData, setImgData] = useState('');
  const [verifyed, setVerifyed] = useState(false);
  const [sessionId, setSessionId] = useState((Guid.NewGuid() as any).ToString());

  useEffect(() => {
    getValidImg();
  }, []);

  // 图形验证码
  const getValidImg = async () => {
    const res: ImgResultType = await Http({
      url: API_POST_PNG,
      method: 'post',
      loading: false,
      body: {
        session_id: sessionId,
      },
    });
    if (res?.image_stream) {
      setImgData('data:image/jpg;base64,' + res.image_stream);
    } else {
      Toast.show({
        icon: 'fail',
        content: '获取图片验证码失败'
      });
    }
  };

  const handleCardNoChange = (val: string) => {
    setCardNo(val);
    checkVerify({ cardNo: val });
    if (!val) {
      setCardNoTip('身份证号不能为空');
    } else if (!REG_ID_CARD.test(val)) {
      setCardNoTip('身份证号格式不正确');
    } else {
      setCardNoTip('');
    }
  };
  const handleImgNoChange = (val: string) => {
    setImgNo(val);
    checkVerify({ imgNo: val });
    if (!val) {
      setImgNoTip('图形验证码不能为空');
    } else if (!REG_PNG_CODE.test(val)) {
      setImgNoTip('图形验证码格式不正确');
    } else {
      setImgNoTip('');
    }
  };
  const checkVerify = (obj?: VerifyParamType) => {
    const param: VerifyParamType = Object.assign({ cardNo, imgNo }, obj || {});
    if (param.cardNo && param.imgNo && REG_ID_CARD.test(param.cardNo) && REG_PNG_CODE.test(param.imgNo)) {
      setVerifyed(true);
    } else {
      setVerifyed(false);
    }
  };
  const handSubmit = async () => {
    if (!verifyed) return;
    const res:SearchResultType  = await Http({
      url: API_POST_USER_SEARCH,
      method: 'post',
      loading: false,
      returnAll: true,
      body:{
        session_id:sessionId,
        verifycode: imgNo,
        idcardno: cardNo
      }
    });
    if (res.code === 0 && res.data.investorid) {
      const result = await Modal.confirm({
        content: `资金账号为：${res.data.investorid}`,
        confirmText: '复制',
        cancelText: '取消'
      });
      if (result) {
        handleCopyInvestorId(res.data.investorid);
        Toast.show({ content: '复制成功'});
      }
    } else {
      Toast.show({
        icon: 'fail',
        content: res.msg || '查询失败，请稍后再试'
      });
    }
    getValidImg();
  };
  const handleCopyInvestorId = (investorid: string) => {
    const $textarea = document.createElement('textarea');
    $textarea.readOnly = true;
    $textarea.style.position = 'absoult';
    $textarea.style.left = '-9999px';
    $textarea.value = investorid;
    document.body.appendChild($textarea);
    $textarea.select();
    document.execCommand('Copy');
    Toast.show({
      icon: 'success',
      content: '复制成功'
    });
    document.body.removeChild($textarea);
  };

  return (
    <div className='mb-account-search-page'>
      <div className='search-form'>
        <MbFormItem
          value={cardNo}
          required={true}
          label='身份证号'
          tip={cardNoTip}
          parten={REG_ID_CARD}
          placeholder='请输入身份证号'
          setValue={handleCardNoChange}
        />
        <MbFormItem
          value={imgNo}
          required={true}
          label='图形验证码'
          tip={imgNoTip}
          placeholder='请输入图形验证码'
          hasImg={true}
          parten={REG_PNG_CODE}
          imgData={imgData}
          setValue={handleImgNoChange}
          refreshImgData={getValidImg}
        />
      </div>
      <div
        className={cs('search-btn', verifyed ? '' : 'disable')}
        onClick={handSubmit}
      >查询</div>
    </div>
  );
};

export default MbAccountSearch;