import React, { useRef, useEffect, useState, ChangeEvent, useCallback } from 'react';
import cs from 'classnames';
import './index.scss';
import { VerifyParamType } from '../mbSearch/interface';
import Guid from '../../utils/guid';
import { DepartmentItemResultType, DepartmentResultType, ImgResultType, ProvinceResultType } from '../home/interface';
import Http from '../../utils/fetch';
import { CascadePicker, Toast, Modal } from 'antd-mobile';
import MbFormItem from '../../components/mbFormItem';
import { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker-view';
import {
  API_POST_ALL_STORE,
  API_POST_CITY_DATA,
  API_POST_PNG,
  API_POST_SEND_CODE,
  API_POST_SUBMIT,
  REG_PHONE,
  REG_ID_CARD,
  REG_CODE,
  REG_PNG_CODE,
} from '../../config/api';

const MbOpenAccount = () => {
  const phoneRef = useRef(null);
  const phoneImgRef = useRef(null);
  const [sessionId, setSessionId] = useState((Guid.NewGuid() as any).ToString());
  const [verifyed, setVerifyed] = useState(false);
  const [provinceList, setProvinceList] = useState<ProvinceResultType[]>([]);
  const [storeList, setStoreList] = useState<DepartmentItemResultType[]>([]);
  const [imgData, setImgData] = useState('');
  const [userName, setUserName] = useState('');
  const [cardNo, setCardNo] = useState('');
  const [cardNoTip, setCardNoTip] = useState('身份证号不能为空');
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneNoTip, setPhoneNoTip] = useState('手机号不能为空');
  const [imgNo, setImgNo] = useState('');
  const [imgNoTip, setImgNoTip] = useState('图形验证码不能为空');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneCodeTip, setPhoneCodeTip] = useState('短信验证码不能为空');
  const [showPhoneCodeTip, setPhowPhoneCodeTip] = useState(false);
  const [phoneCount, setPhoneCount] = useState(60);
  const [phoneCountStart, setPhoneCountStart] = useState(false);
  const [address, setAddress] = useState('');
  const [province, setProvince] = useState('');
  const [provinceId, setProvinceId] = useState('');
  const [city, setCity] = useState('');
  const [cityId, setCityId] = useState('');
  const [showProvinceCity, setShowProvinceCity] = useState(false);
  const [showProvinceCityTip, setShowProvinceCityTip] = useState(false);
  const [mbCityData, setMbCityData] = useState<ProvinceResultType[]>([]);
  const [mbStoreData, setMbStoreData] = useState<ProvinceResultType[]>([]);
  const [store, setStore] = useState('');
  const [storeId, setStoreId] = useState('');
  const [showStore, setShowStore] = useState(false);
  const [showStoreTip, setStoreTip] = useState(false);

  useEffect(() => {
    const arr = [...provinceList];
    arr.forEach((item) => {
      item.children = item.nodes || [];
    });
    setMbCityData(arr);
  }, [provinceList]);
  useEffect(() => {
    const arr: ProvinceResultType[] = [];
    storeList.forEach((item) => {
      arr.push({
        value: item.departmentid,
        label: item.departmentname,
      });
    });
    setMbStoreData(arr);
  }, [storeList]);
  useEffect(() => {
    if (!showProvinceCity && !province) {
      setShowProvinceCityTip(true);
    }
  }, [showProvinceCity, province]);
  useEffect(() => {
    if (!showStore && !store) {
      setStoreTip(true);
    }
  }, [showStore, store]);
  useEffect(() => {
    getValidImg();
    getProvince();
    getDepartment();
    // 下面两个是为了初次进来不显示提示，且此useEffect必须在最后一个useEffect
    setShowProvinceCityTip(false);
    setStoreTip(false);
  }, []);
  useEffect(() => {
    if (
      userName &&
      cardNo && REG_ID_CARD.test(cardNo) &&
      phoneNo && REG_PHONE.test(phoneNo) &&
      imgNo && REG_PNG_CODE.test(imgNo) &&
      phoneCode && REG_CODE.test(phoneCode) &&
      address &&
      province &&
      provinceId &&
      city &&
      cityId &&
      store &&
      storeId
    ) {
      setVerifyed(true);
    } else {
      setVerifyed(false);
    }
  }, [
    userName,
    cardNo,
    phoneNo,
    imgNo,
    phoneCode,
    address,
    province,
    provinceId,
    city,
    cityId,
    store,
    storeId
  ]);

  // 图形验证码
  const getValidImg = async () => {
    const res: ImgResultType = await Http({
      url: API_POST_PNG,
      method: 'post',
      loading: false,
      body: {
        session_id: sessionId,
      },
    });
    if (res?.image_stream) {
      setImgData('data:image/jpg;base64,' + res.image_stream);
    } else {
      Toast.show({
        icon: 'fail',
        content: '获取图片验证码失败'
      });
    }
  };
  // 获取手机验证码
  const getMessageCode = async () => {
    const res: any = await Http({
      url: API_POST_SEND_CODE,
      method: 'post',
      loading: false,
      returnAll: true,
      body: {
        mobile: phoneNo,
        verifycode: imgNo,
        session_id: sessionId,
      },
    });
    if (res?.code !== 0) {
      Toast.show({
        icon: 'fail',
        content: res?.msg || '验证码无效'
      });
      return false;
    }
    return true;
  };
  // 显示省市
  const getProvince = async () => {
    const res: ProvinceResultType[] = await Http({
      url: API_POST_CITY_DATA,
      method: 'post',
      loading: false,
    });
    if (res?.length) {
      setProvinceList(res || []);
    } else {
      Toast.show({
        icon: 'fail',
        content: '获取省市数据失败'
      });
    }
  };
  // 获取营业部
  const getDepartment = async () => {
    const res: DepartmentResultType = await Http({
      url: API_POST_ALL_STORE,
      method: 'post',
      loading: false,
    });
    if (res?.departmentList) {
      setStoreList(res.departmentList || []);
    } else {
      Toast.show({
        icon: 'fail',
        content: '获取营业部数据失败'
      });
    }
  };

  // 输入框
  const handleUserNameChange = useCallback((val: string) => {
    setUserName(val);
  }, []);
  const handleCardNoChange = useCallback((val: string) => {
    setCardNo(val);
    if (!val) {
      setCardNoTip('身份证号不能为空');
    } else if (!REG_ID_CARD.test(val)) {
      setCardNoTip('身份证号格式不正确');
    } else {
      setCardNoTip('');
    }
  }, []);
  const handlePhoneNoChange = useCallback((val: string) => {
    setPhoneNo(val);
    if (!val) {
      setPhoneNoTip('手机号不能为空');
    } else if (!REG_PHONE.test(val)) {
      setPhoneNoTip('手机号格式不正确');
    } else {
      setPhoneNoTip('');
    }
  }, []);
  const handleImgNoChange = useCallback((val: string) => {
    setImgNo(val);
    if (!val) {
      setImgNoTip('图形验证码不能为空');
    } else if (!REG_PNG_CODE.test(val)) {
      setImgNoTip('图形验证码格式不正确');
    } else {
      setImgNoTip('');
    }
  }, []);
  const handlePhoneCodeInputBlur = useCallback(() => {
    if (!phoneCode) {
      setPhowPhoneCodeTip(true);
      return;
    }
    setPhowPhoneCodeTip(false);
  }, [phoneCode]);
  const handlePhoneCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setPhoneCode(val);
    if (!val) {
      setPhoneCodeTip('短信验证码不能为空');
    } else if (!REG_PHONE.test(val)) {
      setPhoneCodeTip('短信验证码格式不正确');
    } else {
      setPhoneCodeTip('');
    }
  }, []);
  const handleTimeStart = async () => {
    const phoneNoLegal = phoneNo && REG_PHONE.test(phoneNo);
    if (!phoneNoLegal) {
      const dom = phoneRef?.current as HTMLDivElement | null;
      if (dom?.getElementsByTagName) {
        const _input = dom.getElementsByTagName('input')?.[0];
        _input && _input.focus();
        _input && _input.blur();
      }
    }
    const imgNoLegal = imgNo && REG_PNG_CODE.test(imgNo);
    if (!imgNoLegal) {
      const dom = phoneImgRef?.current as HTMLDivElement | null;
      if (dom?.getElementsByTagName) {
        const _input = dom.getElementsByTagName('input')?.[0];
        _input && _input.focus();
        _input && _input.blur();
      }
    }
    if (!phoneNoLegal || !imgNoLegal) return;
    const flag = await getMessageCode();
    if (!flag) {
      getValidImg();
      return;
    }
    setPhoneCountStart(true);
    let num = 59;
    const timer = setInterval(() => {
      if (num <= 0) {
        setPhoneCountStart(false);
        setPhoneCount(60);
        clearInterval(timer);
      } else {
        setPhoneCount(num--);
      }
    }, 1000);
  };
  const handleAddressChange = useCallback((val: string) => {
    setAddress(val);
  }, []);
  const handleProvinceCityConfirm = (val: PickerValue[], extend: PickerValueExtend) => {
    const data: any = extend.items || [];
    if (!data.length) return;
    setProvinceId(data[0]?.value || '');
    setProvince(data?.[0]?.label || '');
    setCityId(data?.[1]?.value || '');
    setCity(data?.[1]?.label || '');
    setShowProvinceCityTip(false);
  };
  const handleStoreConfirm = (val: PickerValue[]) => {
    setStoreId(val?.[0] || '');
    let depName: string = '';
    storeList.forEach((item) => {
      if (item.departmentid === val[0]) {
        depName = item.departmentname || '';
      }
    });
    setStore(depName);
    setStoreTip(false);
  };
  // 提交
  const handSubmit = async () => {
    if (!verifyed) return;
    const res: any = await Http({
      url: API_POST_SUBMIT,
      method: 'post',
      loading: true,
      returnAll: true,
      body: {
        session_id: sessionId,
        name: userName,
        idcardno: cardNo,
        telephone: phoneNo,
        verifycode: imgNo,
        mobilecode: phoneCode,
        province,
        province_value: provinceId,
        city,
        city_value: cityId,
        address,
        departmentid: storeId,
      },
    });
    if (res?.code === 0) {
      Modal.alert({
        content: res?.msg || res?.data?.affect?.errorInfo || '提交成功',
      });
    } else {
      Modal.alert({
        content: res?.msg || res?.data?.affect?.errorInfo || '提交失败',
      });
    }
    getValidImg();
  };

  // 渲染
  const renderSendPhoneCode = () => {
    return (
      <div className='phone-code-input'>
        <div className='mb-form-input'>
          <span className='label'>短信验证码</span>
          <input
            value={phoneCode}
            placeholder={'请输入短信验证码'}
            onChange={(e) => handlePhoneCodeChange(e)}
            onBlur={handlePhoneCodeInputBlur}
          />
          <div className='form-item-img-wrap' onClick={handleTimeStart}>
            {
              phoneCountStart ? 
                <span className='disable'>重新获取({phoneCount})</span> :
                <span>获取验证码</span>
            }
          </div>
        </div>
        {showPhoneCodeTip ? <div className='mb-form-tip'>{phoneCodeTip}</div> : null }
      </div>
    );
  };
  const renderArea = () => {
    return (
      <div className='select-wrap'>
        <div className='mb-form-input'>
          <span className='label'>所在地区</span>
          <input
            className="mb-city-selector"
            readOnly
            onClick={() => setShowProvinceCity(true)}
            placeholder="请选择省市"
            value={province + city}
          />
          <CascadePicker
            title="省市选择"
            confirmText={<span style={{color: '#ff6900'}}>确认</span>}
            options={mbCityData}
            visible={showProvinceCity}
            onClose={() => setShowProvinceCity(false)}
            onConfirm={handleProvinceCityConfirm}
          />
          <i className='gtjaqh icon-youjian icon'></i>
        </div>
        {showProvinceCityTip ? <div className='mb-form-tip'>所在地区不能为空</div> : null }
      </div>
    );
  };
  const renderStore = () => {
    return (
      <div className='select-wrap'>
        <div className='mb-form-input'>
          <span className='label'>拟开户营业部</span>
          <input
            className="mb-city-selector"
            readOnly
            onClick={() => setShowStore(true)}
            placeholder="请选择开户营业部"
            value={store}
          />
          <CascadePicker
            title="选择营业部"
            confirmText={<span style={{color: '#ff6900'}}>确认</span>}
            options={mbStoreData}
            visible={showStore}
            onClose={() => setShowStore(false)}
            onConfirm={handleStoreConfirm}
          />
          <i className='gtjaqh icon-youjian icon'></i>
        </div>
        {showStoreTip ? <div className='mb-form-tip'>开户营业部不能为空</div> : null }
      </div>
    );
  };

  return (
    <div className='mb-open-account-page'>
      <div className='open-form'>
        <MbFormItem
          value={userName}
          required={true}
          label='姓名'
          tip='姓名不能为空'
          placeholder='请输入姓名'
          setValue={handleUserNameChange}
        />
        <MbFormItem
          value={cardNo}
          required={true}
          label='身份证号'
          tip={cardNoTip}
          parten={REG_ID_CARD}
          placeholder='请输入身份证号'
          setValue={handleCardNoChange}
        />
        <MbFormItem
          ref={phoneRef}
          value={phoneNo}
          required={true}
          label='手机号'
          tip={phoneNoTip}
          parten={REG_PHONE}
          placeholder='请输入手机号'
          setValue={handlePhoneNoChange}
        />
        <MbFormItem
          ref={phoneImgRef}
          value={imgNo}
          required={true}
          label='图形验证码'
          tip={imgNoTip}
          placeholder='请输入图形验证码'
          hasImg={true}
          imgData={imgData}
          parten={REG_PNG_CODE}
          setValue={handleImgNoChange}
          refreshImgData={getValidImg}
        />
        {renderSendPhoneCode()}
        {renderArea()}
        <MbFormItem
          value={address}
          required={true}
          isMutl={true}
          label='详细地址'
          tip='详细地址不能为空'
          placeholder='请输入详细地址，精确到街道门牌号等'
          setValue={handleAddressChange}
        />
        {renderStore()}
      </div>
      <div
        className={cs('open-btn', verifyed ? '' : 'disable')}
        onClick={handSubmit}
      >提交</div>
    </div>
  );
};

export default MbOpenAccount;